
.divContent{
background-image: url('../../assets/fondobody.png');
background-size: cover;
background-repeat: repeat;
background-position: right;
}

.divContent2{
background-image: url('../../assets/fondobody.png');
background-size: cover;
background-repeat: repeat;
background-position: right;
margin-top: 140px;
}

.bodyInterno{
width: 65%;
padding: 10px 0px 10px 0px;
margin: -30px 0px -140px 0px;
height: fit-content;
}

.iconosContacto{
display: flex;
flex-direction: row;
justify-content: space-around;
}

.iconQS{
  width: 150px;
  height: 90px;
}

.textAlign{
  text-align: justify;
}

.registro{
  margin: 0px;
}

@media (max-width: 768px) {

  .bodyInterno{
    width: 90%;  
    margin: 0px 0px 0px 0px;
    height: fit-content;
  }  

  .divContent{
    height: fit-content;
  }

   .divContent2{
    margin-top: 0px;
    height: 400px;
  }

  .iconosContacto{
    display: flex;
    flex-direction: column;
    width: 100%;;
  }

  .registro{
    padding-top: 120px;
  }

  .iconSize{
    width: 100%;
  }

}
