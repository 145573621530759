.moveIn {
    animation-duration: 1s;
    animation-name: slideOut;
    animation-iteration-count: 1;
}

.imgBarFondo {
  background-image: url('../../assets/datoscontactofooter.png');
  background-repeat:no-repeat;
  background-size: contain;
  background-position:center center;
}

.imgFondoBdy {
  background-image: url('../../assets/fondobody.png');
  background-repeat:no-repeat;
  background-size: contain;
  background-position: right;
}

.imgFondoCut {
  background-image: url('../../assets/headerCut.png');
  background-size: cover;
  background-repeat: repeat;
}

.divContent{
  background-image: url('../../assets/fondobody.png');
  background-size: cover;
  background-repeat: repeat;
  background-position: right;
}

.barra{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 48px;
}

.btnHmbgr{
  display: none;
}

.menuIcon{
    display: none; 
}

.menuIcoff{
  display: block; 
  width: fit-content;
  color: black;
  background-color: aquamarine;  
}

.bdyMenu{
  display: block;
}

.bdyMenuOff{
  display: none;
}

.bodyInterno{
  width: 65%;
  padding: 10px 0px 10px 0px;
  margin: -30px 0px -140px 0px;
}

.iconosContacto{
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.iconSize{
  width: 33%;
  padding: 10px;
}

@media (max-width: 768px) {
    .menuIcon{
      display: none;
    }

    .btnHmbgr{
      display: block;
    }

    .bodyInterno{
      width: 90%;  
      margin: 0px 0px 0px 0px;
      height: fit-content;
    }  

    .divContent{
      height: fit-content;
    }

    .iconosContacto{
      display: flex;
      flex-direction: column;
    }

    .iconSize{
      width: 100%;
    }

  }
